import gsap from "gsap";

export default class Header {
    constructor(rootNode, props) {
        this.root = rootNode;
        this.props = props;

        if (!this.props.isTransparent) return;

        this.intervalSpeed = 500; //ms
        this.scrollThreshold = 500; //px

        this.isVisible = false;

        this.initScrollInterval();
    }

    initScrollInterval() {
        setInterval(() => this.checkScroll(), this.intervalSpeed);
    }

    checkScroll() {
        if (this.isVisible == window.scrollY > this.scrollThreshold) return; //already in the right state

        this.isVisible = window.scrollY > this.scrollThreshold;

        this.isVisible ? this.fadeIn() : this.fadeOut();
    }

    fadeIn() {
        const animation = gsap.timeline({
            onStart: () => {
                this.root.classList.remove("no-bg");
            },
        });

        animation.from(this.root, { yPercent: -100, ease: "power2.out" });
    }

    fadeOut() {
        const animation = gsap.timeline({
            onComplete: () => {
                this.root.classList.add("no-bg");
                gsap.set(this.root, { yPercent: 0 });
            },
        });

        animation.to(this.root, { yPercent: -100, ease: "power2.in" });
    }
}
