export function isEditMode() {
    return document.body.getAttribute("data-editmode") === "true";
}

export const breakpoints = {
    xs: 380,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
    xxxl: 1680,
    max: 1920,
};

export function validate() {
    if(!this.$refs.checkOnChange) return

    let errors = 0

    this.$refs.inputs.forEach((input) => {
        const row = input.closest('.form-row')

        if(!row) return

        if(input.validity.valid) {
            row.classList.remove('has-error')
        } else {
            row.classList.add('has-error')
            if (row.querySelector('.error-message')) {
                row.querySelector('.error-message').innerHTML = input.validationMessage
            }
            errors++
        }
    })

    return (errors <= 0)
}

export function submit(e) {
    e.preventDefault();

    this.$refs.checkOnChange = true
    const validation = validate.bind(this);

    if(validation()) this.$refs.form.submit()
}