import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class MainHero {
    constructor(rootNode) {
        this.root = rootNode;

        if (innerWidth > 768) {
            this.initParallax();
        } else {
            this.initLogoAnimation();
        }
    }

    initParallax() {
        const animation = gsap.timeline({ defaults: { ease: "linear" } });

        animation.from(
            this.root.querySelector(".bg-image"),
            {
                scale: 1.5,
            },
            "p"
        );

        animation.to(
            this.root.querySelector(".bg-image"),
            {
                yPercent: -20,
            },
            "p"
        );

        animation.to(
            this.root.querySelector(".hero-container"),
            {
                y: -innerHeight,
            },
            "p"
        );

        this.addLogoAnimation(animation);

        ScrollTrigger.create({
            animation,
            trigger: this.root,
            start: "top top",
            end: "bottom center",
            scrub: true,
            pin: true,
            // markers: true,
        });
    }

    initLogoAnimation() {
        const animation = gsap.timeline({ defaults: { ease: "linear" } });

        this.addLogoAnimation(animation);

        ScrollTrigger.create({
            animation,
            trigger: this.root,
            start: "top top",
            end: "bottom-=20% center",
            scrub: true,
            // markers: true,
        });
    }

    addLogoAnimation(animation) {
        const logo = [
            this.root.querySelector(".logo-left"),
            this.root.querySelector(".logo-right"),
        ];

        const width = [
            logo[0].getBoundingClientRect().width,
            logo[1].getBoundingClientRect().width,
        ];

        const offset = [
            width[0] + logo[0].getBoundingClientRect().left,
            width[1] + logo[1].getBoundingClientRect().left,
        ];

        animation.from(logo[0], { x: -offset[0] }, "p");
        animation.from(logo[1], { x: offset[1] }, "p");
    }
}
