import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);

export default class Images {
    constructor(rootNode) {
        const swiper = new Swiper(rootNode.querySelector(".swiper"), {
            spaceBetween: 100,
            loop: true,
            navigation: {
                nextEl: rootNode.querySelector(".swiper-button-next"),
                prevEl: rootNode.querySelector(".swiper-button-previous"),
            },
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true
            },
        });
    }
}
