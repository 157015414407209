import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class FullWidthQuote {
    constructor(rootNode) {
        this.root = rootNode;

        if (innerWidth > 768) this.initScrollAnimation();
    }

    initScrollAnimation() {
        const animation = gsap.timeline({ defaults: { ease: "linear" } });

        animation.from(this.root.querySelector("figure"), {
            opacity: 0,
            y: 100,
        });

        animation.to(
            this.root.querySelector("figure"),
            {
                y: -innerHeight,
            },
            "p"
        );

        animation.to(
            this.root,
            {
                "--overlay-opacity": 0,
            },
            "p"
        );

        animation.to(
            this.root.querySelector(".icon"),
            {
                yPercent: -80,
            },
            "p"
        );

        ScrollTrigger.create({
            animation,
            trigger: this.root,
            start: "top top",
            end: "bottom center",
            scrub: true,
            // markers: true,
            pin: true,
        });
    }
}
