export default class Index {
    constructor(rootNode, props) {
        this.$refs = {
            rootNode,
            hotspots: rootNode.querySelectorAll('.hotspot'),
        };

        [...this.$refs.hotspots].forEach(hotspot => {
            hotspot.content = hotspot.querySelector('.hotspot');
            hotspot.addEventListener('click', this.toggleItem.bind(this));
        });
    }

    toggleItem(event) {
        const id = event.currentTarget.getAttribute('data-hotspot');
        [...this.$refs.rootNode.querySelectorAll('.hotspot-' + id)].forEach(hotspot => {
            if (hotspot.classList.contains('open')) {
                hotspot.classList.remove('open');
            } else {
                hotspot.classList.add('open');
            }
        })
    }
}
