import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ParallaxIntro {
    constructor(rootNode) {
        this.root = rootNode;

        this.initParallax();
    }

    initParallax() {
        const animation = gsap.timeline({
            defaults: { ease: "linear" },
        });

        animation.fromTo(
            this.root.querySelector(".headline"),
            {
                opacity: 0,
                y: 100,
            },
            {
                opacity: 1,
                y: 0,
            }
        );
        animation.to(this.root.querySelector(".headline"), {
            opacity: 0,
        });
        const svgs = this.root.querySelectorAll(".mask > svg");

        animation.fromTo(
            svgs[0],
            { xPercent: -100 },
            { xPercent: 10 },
            "p-=0.2"
        );
        animation.fromTo(
            svgs[1],
            { xPercent: 100 },
            { xPercent: -10 },
            "p-=0.2"
        );
        animation.to(this.root.querySelector("img"), { opacity: 1 }, "pp");
        animation.to(
            this.root.querySelectorAll(".mask > svg")[0],
            { opacity: 0 },
            "pp"
        );
        animation.to(
            this.root.querySelectorAll(".mask > svg")[1],
            { opacity: 0 },
            "pp"
        );

        ScrollTrigger.create({
            animation,
            trigger: this.root,
            start: "top 10%",
            end: "bottom+=50% 20%",
            pin: true,
            scrub: true,
            invalidateOnRefresh: true,
            // markers: true,
        });
    }
}
