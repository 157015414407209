import { isEditMode } from "../../../assets/src/js/utils";
import { breakpoints } from "../../../assets/src/js/utils";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class MainHero {
    constructor(rootNode) {
        if (isEditMode()) {
            return;
        }

        this.root = rootNode;

        this.initParallax();

        window.addEventListener("resize", () => {
            ScrollTrigger.refresh();
        });
    }

    initParallax() {
        this.root
            .querySelector(".logo-animation")
            .classList.remove("invisible");

        const matchMedia = gsap.matchMedia();

        matchMedia
            .add(`(min-width: ${breakpoints.md}px)`, () => {
                // Desktop Animation
                const animation = gsap.timeline({
                    defaults: { ease: "linear" },
                });

                animation.from(
                    this.root.querySelector(".bg-image"),
                    {
                        scale: 1.5,
                    },
                    "p"
                );

                animation.to(
                    this.root.querySelector(".bg-image"),
                    {
                        yPercent: -20,
                    },
                    "p"
                );

                animation.to(
                    this.root.querySelector(".hero-container"),
                    {
                        y: -innerHeight,
                    },
                    "p"
                );

                this.addLogoAnimation(animation);

                ScrollTrigger.create({
                    animation,
                    trigger: this.root,
                    start: "top top",
                    end: "bottom center",
                    scrub: true,
                    pin: true,
                    invalidateOnRefresh: true,
                    // markers: true,
                });
            })
            .add(`(max-width: ${breakpoints.md - 1}px)`, () => {
                // Mobile Animation
                const animation = gsap.timeline({
                    defaults: { ease: "linear" },
                });

                this.addLogoAnimation(animation);

                ScrollTrigger.create({
                    animation,
                    trigger: this.root,
                    start: "top top",
                    end: "bottom center",
                    scrub: true,
                    invalidateOnRefresh: true,
                    onRefresh: () => {
                        animation.render(0.0001); // in order to avoid "blinking"
                    },
                    // markers: true,
                });
            });
    }

    getXOffset(i) {
        const selector = ".logo-" + (i == 0 ? "left" : "right");

        const logo = this.root.querySelector(selector);

        const width = logo.getBoundingClientRect().width;

        const offset = width + logo.getBoundingClientRect().left;

        return offset;
    }

    addLogoAnimation(animation) {
        const logo = [
            this.root.querySelector(".logo-left"),
            this.root.querySelector(".logo-right"),
        ];
        animation.from(logo[0], { x: () => -this.getXOffset(0) }, "p");
        animation.from(logo[1], { x: () => this.getXOffset(1) }, "p");
    }
}
